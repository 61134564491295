<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center" style="height:320px;"
        :style="{ backgroundImage: `url(${require('@core/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
                  <br/>
                        <br/>
                              <br/>
            Hello,
            <br/>
        
            It looks like  you have been managing  more then one account. 
                    <br/>
                          <br/>
                    Choose the account you would like to work on.
          </h2>
          <b-card-text class="mb-2">
            <span>Which account you would like to work on?  </span>
  <span class="font-weight-bolder">
    You will be working on same account until you log out and log in again.
    
    
    </span>
          </b-card-text>

          <!-- form -->
         
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in companies"
          :key="item.id"
          
          md="5"
          sm="3"
          class="kb-search-content ml-5 "
        >
          <b-card v-if="item.ClientSetting"
            class="text-center cursor-pointer mt-3"
            :img-src="logo(item)"
           img-alt="item.img.slice(5)"
           
            img-top
            @click="chooseCompany(item.apiKey)"
          >
            <h4 class="" v-if="item.ClientSetting.generalsettings">   {{ JSON.parse(item.ClientSetting.generalsettings).title }} </h4>
            <b-card-text class="mt-1"  v-if="item.ClientSetting.generalsettings">
          {{ JSON.parse(item.ClientSetting.generalsettings).contactname }}   
            </b-card-text>
  <b-card-text class="mt-1"  v-if="!(item.ClientSetting.generalsettings)">
          {{  item  }}   
            </b-card-text>

          </b-card>
          <div v-if="!(item.ClientSetting)" > no data  {{  item }}  </div>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
      </b-row>
    </section>
  </div>
</template>


<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
     BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
       knowledgeBaseSearchQuery: '',
      kb: [],
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      companies:[]
    }
  },
  created(){
       this.companies= JSON.parse(JSON.stringify( this.$route.params.companies));
       console.log(this.companies);

       this.userEmail=this.$route.params.u;
         this.password=this.$route.params.p;
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
   computed: {
    filteredKB() {
      
      return [{'id':"1","title":"Company 1", "desc":"Desctiption info1 ",img:"assets/images/illustration/demand.svg"},{'id':"2","title":"Company 2", "desc":"Desctiption info"
      ,img:"assets/images/illustration/sales.svg"}];
    },

  },
  methods: {
         logo(item) {
      if (item.ClientSetting==undefined||item.ClientSetting.logo==undefined||item.ClientSetting==null||item.ClientSetting.logo==null)
      {

        var cdnHost=process.env.VUE_APP_CDN_HOST;
       

       return cdnHost+"/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png";
      }
      console.log(item.ClientSetting.logo);
return item.ClientSetting.logo;

      return [{'id':"1","title":"Company 1", "desc":"Desctiption info1 ",img:"assets/images/illustration/demand.svg"},{'id':"2","title":"Company 2", "desc":"Desctiption info"
      ,img:"assets/images/illustration/sales.svg"}];
    },
      chooseCompany(company)
      {
          console.log(company);
        localStorage.setItem("clientToken",company);


         useJwt.logincompany({
            username: this.userEmail,
            password: this.password,
            clienttoken:company
          }).then(response => {
              console.log(response);
              
                  if (!response.data.accessToken)  {
                    console.log('handle error');
              this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Can't login. Please check your credentials`,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                   //  this.$refs.loginValidation.setErrors('test')
                   return;
                  };

                
                  useJwt.setToken(response.data.accessToken);
             
                  useJwt.getModules({
            
          }) .then(roleresponse => {
            console.log("Roles Initiated");
          
            var modules=roleresponse.data.data;
            var navItems=[];
            for (let index = 0; index < modules.length; index++) {
              const module = modules[index];
              console.log("Module:",module);
              var moduleFeatures=module.ClientModuleFeatures;
              console.log("ClientModuleFeatures:",moduleFeatures);
              if (moduleFeatures==undefined||moduleFeatures==null||moduleFeatures.length==0)
              {
                continue;
              }
              console.log("ClientModuleFeatures:",moduleFeatures);
              var navItem={header:module.ProjectModule.title};
              navItems.push(navItem);
              for (let elindex = 0; elindex < moduleFeatures.length; elindex++) {
                const featureelement = moduleFeatures[elindex];
                console.log("featureelement:",featureelement);
                var feature=featureelement.ModuleFeature;
                console.log("feature:",feature);
                var navFEtItem=
                {title:feature.title,
                  icon:feature.icon,
                 id:feature.id,
                  route:feature.name };
                  navItems.push(navFEtItem);
                
              }
               
            }

            console.log(navItems);
            localStorage.setItem("projectSettings",JSON.stringify(navItems) );
              useJwt.setRefreshToken(null);
              localStorage.setItem("userData", JSON.stringify(response.data));

            


        

            
              const { userData } = response.data
             
                    console.log(response.data);


                      this.$router.replace("/").then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome`,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
              });

        
            //   useJwt.setToken(response.data.accessToken)
            //   useJwt.setClientLogo(response.data.setting.logo)
            //   // useJwt.setRefreshToken(response.data.refreshToken)
            //   localStorage.setItem('userData', JSON.stringify(response.data))
            // //  this.$ability.update(userData.ability)
            // localStorage.setItem('needstogoLogin',false)
              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
           this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            //   this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            //     .then(() => {
            //       this.$toast({
            //         component: ToastificationContent,
            //         position: 'top-right',
            //         props: {
            //           title: `Welcome ${userData.fullName || userData.username}`,
            //           icon: 'CoffeeIcon',
            //           variant: 'success',
            //           text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //         },
            //       })
            //     })
            })
          })
            .catch(exc => {
              console.log(exc);
               

            //  this.$refs.loginForm.setErrors(error.response.data.error)
            })



      }
    
  },
}
</script>
<style scoped>
#knowledge-base-content img
{
    height:200px;
    object-fit: contain;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
